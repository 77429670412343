.color-primary {
  color: var(--ion-color-primary);
  border-color: var(--ion-color-primary);
}

.color-secondary {
  color: var(--ion-color-secondary);
  border-color: var(--ion-color-secondary);
}

.color-tertiary {
  // FIXME: tertiary ist aktuell noch blau, soll aber orange werden oder color tertiary soll color-warning heißen
  color: var(--ion-color-warning);
  border-color: var(--ion-color-warning);
}

.alternative-background {
  background-color: var(--ion-color-dark);
}

.medium-background {
  background-color: var(--ion-color-medium);
}

.extended-grid {
  --ion-grid-width-xl: var(--extended-grid-width);
}

.full-width {
  width: 100%;
}

.bottom-row-padding {
  margin-bottom: var(--default-space);
}
