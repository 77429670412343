// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  /** primary **/
  --ion-color-primary: #00f0b4;
  --ion-color-primary-rgb: 0, 240, 180;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #00b88a;
  --ion-color-primary-tint: #64ffd8;

  /** secondary **/
  --ion-color-secondary: #9146ff;
  --ion-color-secondary-rgb: 145, 70, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #7e3ddf;
  --ion-color-secondary-tint: #a061fe;

  /** tertiary **/
  --ion-color-tertiary: #000fff;
  --ion-color-tertiary-rgb: 0, 0, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #0410bf;
  --ion-color-tertiary-tint: #3745ff;

  /** success **/
  --ion-color-success: #00ffff;
  --ion-color-success-rgb: 255, 255, 100;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #00b9b9;
  --ion-color-success-tint: #37fbfb;

  /** warning **/
  --ion-color-warning: #f7a512;
  --ion-color-warning-rgb: 247, 165, 18;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #ff005a;
  --ion-color-danger-rgb: 255, 0, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #00323c;
  --ion-color-dark-rgb: 0, 50, 60;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #002830;
  --ion-color-dark-tint: #004452;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #d7d8da;
  --ion-color-medium-tint: #f5f6f9;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** Custom variables **/
  --base-space: 1rem;
  --default-space: 2rem;
  --icon-size: 3rem;
  --large-space: 4rem;
  --header-height: 6rem;
  --header-height-xl: 8rem;
  /* stylelint-disable-next-line rem-over-px/rem-over-px */
  --extended-grid-width: 1600px;
}
