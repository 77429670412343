// Neue Machina Inktrap
@font-face {
  font-family: "Neue Machina Inktrap";
  font-weight: 450;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/neue_machina/PPNeueMachina-InktrapRegular.otf") format("OpenType");
}

// IBM Plex Mono
@font-face {
  font-family: "IBM Plex Mono";
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_mono/IBMPlexMono-Thin.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Mono";
  font-weight: 100;
  font-style: italic;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_mono/IBMPlexMono-ThinItalic.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Mono";
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_mono/IBMPlexMono-ExtraLight.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Mono";
  font-weight: 200;
  font-style: italic;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_mono/IBMPlexMono-ExtraLightItalic.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Mono";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_mono/IBMPlexMono-Light.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Mono";
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_mono/IBMPlexMono-LightItalic.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Mono";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_mono/IBMPlexMono-Light.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Mono";
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_mono/IBMPlexMono-LightItalic.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Mono";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_mono/IBMPlexMono-Regular.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Mono";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_mono/IBMPlexMono-Italic.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Mono";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_mono/IBMPlexMono-Medium.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Mono";
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_mono/IBMPlexMono-MediumItalic.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Mono";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_mono/IBMPlexMono-SemiBold.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Mono";
  font-weight: 600;
  font-style: italic;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_mono/IBMPlexMono-SemiBoldItalic.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Mono";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_mono/IBMPlexMono-Bold.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Mono";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_mono/IBMPlexMono-BoldItalic.ttf") format("TrueType");
}

// IBM Plex Sans
@font-face {
  font-family: "IBM Plex Sans";
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_sans/IBMPlexSans-Thin.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-weight: 100;
  font-style: italic;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_sans/IBMPlexSans-ThinItalic.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_sans/IBMPlexSans-ExtraLight.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-weight: 200;
  font-style: italic;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_sans/IBMPlexSans-ExtraLightItalic.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_sans/IBMPlexSans-Light.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_sans/IBMPlexSans-LightItalic.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_sans/IBMPlexSans-Regular.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_sans/IBMPlexSans-Italic.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_sans/IBMPlexSans-Medium.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_sans/IBMPlexSans-MediumItalic.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_sans/IBMPlexSans-SemiBold.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-weight: 600;
  font-style: italic;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_sans/IBMPlexSans-SemiBold.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_sans/IBMPlexSans-Bold.ttf") format("TrueType");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url("../assets/fonts/ibm_plex_sans/IBMPlexSans-BoldItalic.ttf") format("TrueType");
}

* {
  font-family: "IBM Plex Sans", Helvetica, sans-serif;
}

// For responsiveness across screens
html {
  font-size: 100%;

  @media (width >=576px) {
    font-size: 110%;
  }

  @media (width >=768px) {
    font-size: 120%;
  }

  @media (width >=992px) {
    font-size: 140%;
  }

  @media (width >=1200px) {
    font-size: 150%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "IBM Plex Mono", Courier, monospace;
}

h1 {
  margin-top: var(--default-space);
  font-size: 2rem;
  color: var(--ion-color-primary);

  app-intro-block & {
    hyphens: auto;
  }
}

h2 {
  font-size: 1.5rem;
  color: var(--ion-color-secondary);
  text-align: start;

  :not(ion-text) > & {
    margin-top: var(--default-space);
    margin-bottom: var(--base-space);
  }

  .footer & {
    /* stylelint-disable-next-line rem-over-px/rem-over-px */
    margin-top: 16px;
    /* stylelint-disable-next-line rem-over-px/rem-over-px */
    margin-bottom: 10px;

    font-family: "Neue Machina Inktrap", Courier, monospace;
    font-size: 1rem;
    font-feature-settings: "calt" 1, "rlig" 1, "rvrn" 1, "kern" 1, "rclt" 1, "ss06" 1, "ss05" 1,
      "ss04" 1, "ss07" 1, "ss11" 1, "liga" 1, "dlig" 1;
    color: var(--ion-text-color);
    letter-spacing: 0.1rem;
  }

  .alternative-background :is(ion-text) > & {
    color: var(--ion-color-medium);
  }
}

.teaser-text {
  * {
    font-family: "Neue Machina Inktrap", Courier, monospace;
    font-size: clamp(1.2rem, 4.5vw, 5rem);
    font-feature-settings: "calt" 1, "rlig" 1, "rvrn" 1, "kern" 1, "rclt" 1, "ss06" 1, "ss05" 1,
      "ss04" 1, "ss07" 1, "ss11" 1, "liga" 1, "dlig" 1;
    line-height: 1.3325;
  }

  a,
  span {
    padding: 0.18em 0.24em 0.11em;

    text-decoration: none;
    white-space: nowrap;

    background-color: var(--ion-background-color);
    border: 0.075em solid;

    app-paragraph & {
      background-color: var(--ion-color-dark);
    }

    &.color-secondary.front-page {
      margin-left: -0.315em;
    }

    @media (width > 192px) and (width <=282px) {
      &.color-primary.front-page {
        margin-left: -0.315em;
      }
    }

    @media (width <=340px) {
      &.color-tertiary.front-page {
        margin-left: -0.315em;
      }
    }
  }
}

.icon-card {
  ion-card-title {
    font-family: "IBM Plex Mono", Courier, monospace;
    font-size: 0.9rem;
    font-feature-settings: "calt" 1, "rlig" 1, "rvrn" 1, "kern" 1, "rclt" 1, "ss06" 1, "ss05" 1,
      "ss04" 1, "ss07" 1, "ss11" 1, "liga" 1, "dlig" 1;
    line-height: 1.2;
    text-align: center;

    @media (width <=992px) {
      font-size: 1.25rem;
    }
  }

  ion-card-subtitle {
    padding-bottom: 0.6rem;
    font-family: "IBM Plex Mono", Courier, monospace;
    font-size: 0.7rem;
    text-align: center;

    @media (width <=992px) {
      font-size: 1rem;
    }
  }

  ion-card-content {
    p {
      font-size: 0.7rem;

      @media (width <=992px) {
        font-size: 1rem;
      }
    }
  }
}

.image-description {
  font-size: var(--base-space);
  font-style: italic;
}
